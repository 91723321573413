import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  measures,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const StoreItemGraInvoiceReport = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const measureOptions = measures();

  useEffect(() => {
    authCheck(state.authData);
    getSuppliers();
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const postApi = () => {
    const postData = {
      data: {
        customer,
        from,
        to,
        fromInvoice,
        toInvoice,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }
    if (fromInvoice && toInvoice) {
      var deliveryDateValidInvoice = dateGreaterOrEqual(toInvoice, fromInvoice, 'toInvoice', 'Invalid To Date');
    } else {
      var deliveryDateValidInvoice = true;
    }

    if (!validate || !deliveryDateValid || !deliveryDateValidInvoice) {
      return false;
    }
    let cust = customer.toString();

    const formatDate = (date: string) => {
      let currDate = new Date(date).toLocaleDateString('en-GB');
      return currDate;
    };

    showLoader();
    axios
      .get(
        baseUri() +
          'store-item-gra/invoice-report?user=' +
          cust +
          '&graFrom=' +
          from +
          '&graTo=' +
          to +
          '&invoiceFrom=' +
          fromInvoice +
          '&invoiceTo=' +
          toInvoice +
          '&accountFrom=' +
          fromAccount +
          '&accountTo=' +
          toAccount,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        response.data.data.result = response.data.data.result.map((data: any) => {
          let currData = { ...data };
          return currData;
        });
        setItemData(response.data.data.result);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.result);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [fromInvoice, setFromInvoice] = useState('');
  const [toInvoice, setToInvoice] = useState('');

  const [fromAccount, setFromAccount] = useState('');
  const [toAccount, setToAccount] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    let match = [];
    match = tableData.find((o, i) => {
      if (o.id == id) {
        return true;
      }
    });
    setShowData(match);
    setIsModalOpen(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Store Item GRA Invoice Details</h5>
          <table className="pop-up-table">
            <tr>
              <td>Customer </td>
              <td>{showData.name}</td>
            </tr>
            <tr>
              <td>Item </td>
              <td>{showData.item_name}</td>
            </tr>
            <tr>
              <td>UOM </td>
              <td>{measureOptions.find((o) => o.value === showData.measure).label}</td>
            </tr>
            <tr>
              <td>Invoice. No. </td>
              <td>{showData.invoice_number}</td>
            </tr>
            <tr>
              <td>PO No. </td>
              <td>{showData.po}</td>
            </tr>
            <tr>
              <td>Order Qty </td>
              <td>{showData.ordered_qty}</td>
            </tr>
            <tr>
              <td>Accepted Qty </td>
              <td>{showData.accepted_qty}</td>
            </tr>
            <tr>
              <td>Rate Per Qty </td>
              <td>{showData.rate_per_qty}</td>
            </tr>
            <tr>
              <td>Tax %</td>
              <td>{showData.tax}</td>
            </tr>
            {showData.igst ? (
              <tr>
                <td>IGST </td>
                <td>{showData.igst}</td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>CGST </td>
                  <td>{showData.cgst}</td>
                </tr>
                <tr>
                  <td>SGST </td>
                  <td>{showData.cgst}</td>
                </tr>
              </>
            )}
            <tr>
              <td>Total </td>
              <td>{showData.total}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
      case 'fromInvoice':
        setFromInvoice(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('toInvoice')[0].classList.add('require');
        } else {
          document.getElementsByClassName('toInvoice')[0].classList.remove('require');
        }
        break;
      case 'toInvoice':
        setToInvoice(event.target.value);
        break;
      case 'fromAccount':
        setFromAccount(event.target.value);
        break;
      case 'toAccount':
        setToAccount(event.target.value);
        break;
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
  };

  const clearFormData = () => {
    setFrom('');
    setTo('');
    setFromInvoice('');
    setToInvoice('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setFromAccount('');
    setToAccount('');
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getSuppliers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=3', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'GRANUMBER',
      selector: (row) => `${row.GRANUMBER}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'GRADATE',
      selector: (row) => `${row.GRADATE}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'PARTYCOD',
      cell: (row) => `${row.PARTYCOD}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'VALUE',
      selector: (row) => `${row.VALUE}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'GVAL',
      selector: (row) => `${row.GVAL}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'INVOICEREF',
      selector: (row) => `${row.INVOICEREF}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'INVDATE',
      selector: (row) => `${row.INVDATE}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'GRAGROUP',
      selector: (row) => `${row.GRAGROUP}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'AV0',
      selector: (row) => `${row.AV0}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'AV5',
      selector: (row) => `${row.AV5}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'AV12',
      selector: (row) => `${row.AV12}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'AV18',
      selector: (row) => `${row.AV18}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'AV28',
      selector: (row) => `${row.AV28}`,
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'SGST',
      selector: (row) => `${row.SGST}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'CGST',
      selector: (row) => `${row.CGST}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'IGST',
      selector: (row) => `${row.IGST}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'REMARKS',
      selector: (row) => `${row.REMARKS}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'TCS',
      selector: (row) => `${row.TCS}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'TDS 0.1%',
      selector: (row) => `${row.TDS_PERC}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'TDS',
      selector: (row) => `${row.TDS}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'ROUNDING',
      selector: (row) => `${row.ROUNDING}`,
      sortable: true,
      maxWidth: '5%',
    },
  ];
  const filterColumns = ['GRANUMBER', 'PARTYCOD', 'INVOICEREF'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  return (
    <>
      {popup}
      <SEO title="Store Item GRA Invoice Report Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Store Item GRA Invoice Report Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Supplier <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Supplier *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Gra From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Gra To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Invoice From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="fromInvoice"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={fromInvoice}
                      className="fromInvoice"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Invoice To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="toInvoice"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={toInvoice}
                      className="toInvoice"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Account From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="fromAccount"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={fromAccount}
                      className="fromAccount"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Account To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="toAccount"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={toAccount}
                      className="toAccount"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Store Item GRA Invoice Report</header>
            <CardBody>
              {showDownload && (
                <p>
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StoreItemGraInvoiceReport;
